// WorkExperienceContent.js
import React from 'react';

function WorkExperienceContent() {
    return (
        <>
            <h1>Work Experience</h1>
            <p>This is the content for the Work Experience section.</p>
        </>
    );
}

export default WorkExperienceContent;
