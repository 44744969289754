// Menu.js
import React, { useState } from 'react';
import { ActionMenu, ActionList, Box } from '@primer/react';
import { HomeIcon, CodeIcon, WorkflowIcon, InfoIcon } from '@primer/octicons-react';

import HomeContent from './HomeContent';
import PortfolioContent from './PortfolioContent';
import WorkExperienceContent from './WorkExperienceContent';

const menuItems = [
    {
        name: '主页',
        value: 'home',
        icon: HomeIcon,
    },
    {
        name: 'Portfolio',
        value: 'portfolio',
        icon: CodeIcon,
    },
    {
        name: '工作经历',
        value: 'work_experience',
        icon: WorkflowIcon,
    },
    {
        name: '关于',
        value: 'about',
        icon: InfoIcon,
    },
];

function Menu() {
    const [currentContent, setCurrentContent] = useState(menuItems[0].value);

    const handleSelect = (value) => {
        setCurrentContent(value);
    };

    const current = menuItems.find(item => item.value === currentContent);

    let contentComponent;
    switch (currentContent) {
        case 'home':
            contentComponent = <HomeContent />;
            break;
        case 'portfolio':
            contentComponent = <PortfolioContent />;
            break;
        case 'work_experience':
            contentComponent = <WorkExperienceContent />;
            break;
        case 'about':
            contentComponent = <WorkExperienceContent />;
            break;
        default:
            contentComponent = null;
    }

    return (
        <div>
            <Box sx={{ position: 'absolute', top: 0, left: 0, p: 3 }}>
                <ActionMenu>
                    <ActionMenu.Button size="small">
                        <current.icon />
                        <Box sx={{ display: 'inline-block', ml: 2 }}>
                            {current.name}
                        </Box>
                    </ActionMenu.Button>
                    <ActionMenu.Overlay align="start" side="outside-top">
                        <ActionList showDividers>
                            <ActionList.Group selectionVariant="single">
                                {menuItems.map((item) => (
                                    <ActionList.Item
                                        key={item.value}
                                        href="#"
                                        selected={item.value === currentContent}
                                        onSelect={() => handleSelect(item.value)}
                                    >
                                        {item.name}
                                    </ActionList.Item>
                                ))}
                            </ActionList.Group>
                        </ActionList>
                    </ActionMenu.Overlay>
                </ActionMenu>
            </Box>
            <Box>
                {contentComponent}
            </Box>
        </div>
    );
}

export default Menu;
