// PortfolioContent.js
import React from 'react';

function PortfolioContent() {
    return (
        <>
            <h1>Portfolio</h1>
            <p>This is the content for the Portfolio section.</p>
        </>
    );
}

export default PortfolioContent;
