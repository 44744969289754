import { ThemeProvider, BaseStyles, Box } from '@primer/react'

import Menu from './components/Menu'
import Playground from './components/Playground'
import ColorModeSwitcher from './components/ColorModeSwitcher'

function App() {
    return (
        <ThemeProvider colorMode="auto">
            <BaseStyles>
                <Box bg='canvas.default'>
                    <Menu />
                    {/* <Playground /> */}
                    <ColorModeSwitcher />
                </Box>
            </BaseStyles>
        </ThemeProvider>
    )
}

export default App

