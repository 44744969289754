// HomeContent.js
import React from 'react';
import { Box, Heading, Text, Link, Octicon } from '@primer/react'
import {
    CheckIcon,
    CommentIcon,
    InfoIcon,
} from '@primer/octicons-react'

import MonaLoadingImage from '../images/mona-loading.gif'

function HomeContent() {
    return (
        <Box
            sx={{
                width: '100%',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                p: 5,
            }}
        >
            <Heading>王大恒 Daaihang Wong</Heading>
            <Text>王的世界站</Text>
            <Box
                sx={{
                    maxWidth: 800,
                    width: '100%',
                    height: 300,
                    bg: 'neutral.emphasisPlus',
                    borderRadius: 2,
                    p: 4,
                    my: 6,
                }}
            >
                <CodeLine icon={CheckIcon} iconColor="success.fg">
                    连通赛博世界的窗口已成功初始化。
                </CodeLine>
                <CodeLine icon={CommentIcon} iconColor="accent.fg">
                    <Text color="text.warning">网站建设中，</Text>
                    正在使用 Primer 构建自己的布局...
                </CodeLine>
                <Box display="inline-block" ml={3} mt={2}>
                    <img
                        src={MonaLoadingImage}
                        alt="mona"
                        width={48}
                        height={48}
                    />
                </Box>
            </Box>
            <Footer />
        </Box>
    )
}

function CodeLine({ icon, iconColor, children }) {
    return (
        <Box sx={{ display: 'flex', color: 'fg.onEmphasis', mb: 2 }}>
            <Box sx={{ display: 'flex', mt: '2px', width: 20, minWidth: 20 }}>
                <Octicon icon={icon} size={16} sx={{ color: iconColor }} />
            </Box>
            <Text
                as="p"
                sx={{ flex: 1, fontSize: 1, fontFamily: 'mono', ml: 2 }}
            >
                {children}
            </Text>
        </Box>
    )
}

function Footer() {
    return (
        <Box sx={{ textAlign: 'center' }}>
            <Text>
                Power by React & Primer Design System. Code by Daaihang Wong.{' '}<br></br>
                <Link href="https://beian.miit.gov.cn/" target="_blank">
                    桂ICP备2022008498号-2
                </Link>
            </Text>
        </Box>
    )
}

export default HomeContent;
